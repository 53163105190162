import React, { useContext, useEffect, useState } from 'react'

import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../Context/ConfigContext';

import { useParams } from 'react-router-dom';
import { Spinner } from '../../Components/Shimmers';
import slugify from 'slugify';
import Carousel from 'react-multi-carousel';
import PartsHorizontalCard from '../../Components/Cards/PartsHorizontalCard';

const PartsBySuppliers = () => {
   
    const location = useLocation();
    const { t } = useTranslation();
    const locale = localStorage.getItem('lang')??'en';
    const {gdcURL} = useContext(ConfigContext);
    const dir = localStorage.getItem('dir');
    var { slug, sup_id, sup_name } = useParams();
    var STR_ID = slug.split('-').slice(-1);
   const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [parts, setParts] = useState([]);
    const [brands, setBrands] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    
    const [responsive, setResponsive] =  useState({
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 10,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 3,
          slidesToSlide: 1 // optional, default to 1.
        }
      })
     
    useEffect(() => {
     
        if(localStorage.getItem('pc_id'))
        {
          setLoading(true);   
        fetch(`${gdcURL}/Suppliers/GetPartsBySupplier?pc_id=${localStorage.getItem('pc_id')}&str_id=${STR_ID}&sup_id=${sup_id}&lang=${locale}`)
        .then((res) => res.json())
        .then((data) => {
            
            setParts(data.data);
            
            setLoading(false)
        })
        .catch((error) => {
            console.error('Fetch error:', error);
            setLoading(false);
        });
        }
    }, [location])
    
    return (
        <main className="main" dir={dir}>
            <div className="container">
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><NavLink to={'/'}><i className="icon-home" /></NavLink></li>
                        <li className="breadcrumb-item"><NavLink to={`/Supplier-Catelog/${sup_id}-${sup_name}`}>{sup_name} - Catelog</NavLink></li>
                        <li className="breadcrumb-item active" aria-current="page">{slug.split('-').slice(0,-1).join(' ')}</li>
                    </ol>
                </nav>
                {!localStorage.getItem('pc_id') && 
                <div className='container mb-1 mt-1 pb-2 pt-2'>
                  <div className='alert alert-info' style={{textAlign: 'center'}}>
                  <div className='col-sm-12'>
                    {
                      localStorage.getItem('lang') && localStorage.getItem('lang') === 'en' ?
                      <div>
                        To ensure you get the right parts that fit your car perfectly, please select your car before exploring our wide range of products. 
                    This helps us tailor the best options specifically for your make and model, guaranteeing a perfect fit and optimal performance every time.
                    <br /><b>Select Your Car</b> Now and start shopping with confidence!</div>
                    :<div>
                      <b>للحصول على القطع المناسبة لسيارتك!</b>
                      <br />
لتضمن حصولك على القطع التي تناسب سيارتك تمامًا، يرجى اختيار سيارتك قبل استكشاف مجموعة منتجاتنا الواسعة. يساعدنا هذا في تقديم أفضل الخيارات التي تتناسب مع طراز سيارتك لضمان ملاءمة مثالية وأداء أمثل في كل مرة.
<br />
<b>اختر سيارتك الآن وابدأ التسوق بثقة!</b>
                      </div>
                    }
                  </div>
                  </div>
                </div>
                }
                
                {localStorage.getItem('pc_id') && 
                <div className="row">
                  <div className="col-lg-9 order-lg-1"> </div>
                  <div className="row pb-4">
                  
                {
                    loading? <div className='col-md-12 pt-5 pb-5'><center><Spinner /></center></div>:
                    parts && parts.map((part, index) => {
                        if(selectedBrands.includes(part.SUP_ID) || selectedBrands.length === 0){
                          var part_slug = slugify(`${part.PRODUCT_GROUP}`.toLowerCase())+'-'+slugify(`${part.ART_ARTICLE_NR}`.toLowerCase())+'-'+STR_ID+'-'+slugify(`${part.SUP_ID}`.toLowerCase())+'-'+slugify(`${part.ART_ID}`.toLowerCase())+"#"+part.VD_PRICE??0;
                            return <PartsHorizontalCard key={`r-part-id-${part.ART_ID}-${index}`} partImg={part.MEDIA_SOURCE} criteria={part.ARTICLE_CRITERIA} url={`/Spare-Parts/Details/${part_slug}`}  id={part.ART_ID}  title={part.PRODUCT_GROUP} number={part.ART_ARTICLE_NR} price={0} mrp={0}  qty={1} imgUrl={''} action={true}  brand={part.ART_SUP_BRAND} suplogo={part.SUP_LOGO_NAME} supid={part.SUP_ID} redLabel={3 === 3?`Aftermarket`: 'Genuine'}/>
                           
                        }
                        
                    })
                }
                </div></div>}
            </div>
        </main>
    );
}
const ImageWithFallback = ({ src, index, alt, fallbackText }) => {
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
  };
  const fallbackStyle = {
    textAlign: 'center',
    fontSize: '10px',
    padding: '10px',
    wordBreak: 'break-word',
    maxWidth: '90%',
  };

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  return (
    <div style={containerStyle}>
      {imgError ? (
        <div style={fallbackStyle}>{fallbackText}</div>
      ) : (
        <img style={{height: '40px', margin: 'auto', float: 'none', marginTop: '5px'}} key={`img-part-${index}`} src={src} alt={alt} onError={handleError}  />
       
      )}
    </div>
  );
};
export default PartsBySuppliers;