import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import { Spinner } from "../../Components/Shimmers";
import axios from "axios";
import Carousel from "react-multi-carousel";

import { useTranslation } from "react-i18next";
import { AddToCart } from "../../Helper/MyCart";
import { ToastContainer, toast } from "react-toastify";
import { AddToCartNotifier } from "../../Components/CartComponents";

const Offers = () => {
  const { apiURL, gdcURL, setCartItemsQty } = useContext(ConfigContext); // Use context directly with useContext
  const [offers, setOffers] = useState([]);
  const [loadingOffers, setLoadingOffers] = useState(true);
  const lang = localStorage.getItem("lang") ?? "en";
  const currency = localStorage.getItem("currency") ?? "aed";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [responsive, setResponsive] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  });

  const GetOffers = async () => {
    try {
      const response = await axios.get(
        `${apiURL}/Offers/GetOffers?country_id=225&lang=${lang}&currency=${currency}`
      );
      console.log("offers", response.data);
      if (response.data.success) {
        setOffers(response.data.data);
        setLoadingOffers(false);
      } else {
        setLoadingOffers(false);
      }
    } catch (error) {
      setLoadingOffers(false);
      // console.error("Error fetching part details:", error);
    }
  };

  useEffect(() => {
    GetOffers();
  }, []); // Added apiURL as a dependency to re-fetch if it changes
  const handleAddToCart = async (
    id,
    number,
    title,
    mrp,
    price,
    selectQty,
    imgUrl,
    url, item
  ) => {
    
   
    await AddToCart(id, number, title, mrp, price, selectQty, imgUrl, url);
    setCartItemsQty();

    toast.success(<AddToCartNotifier item_name={title} item_img={imgUrl} />, {
      onClose: () => {},
    });
    // UpdateCartList(GetCarts());
    // ChangeCartProcessStatus(true);
  };
  return (
    <div className="fff">
      {loadingOffers ? (
        <div className="col-lg-12 justify-content-center">
          <center>
            <Spinner />
          </center>
        </div>
      ) : (
        offers.map(
          (offer, indexOffer) =>
            offer.items &&
            offer.items.length > 0 && (
              <section
                className="featured-products-section"
                key={`offers-${indexOffer}`}>
                <div className="container">
                  <h2 className="section-title heading-border ls-20 border-0">
                    {lang === "ar"
                      ? offer.offer_title_ar
                      : offer.offer_title_en}
                  </h2>

                  <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    infinite={false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    // deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px">
                    {offer.items.map((item, index) => {
                      var partImg = item.MEDIA_SOURCE
                        ? `${gdcURL}/images/${item.MEDIA_SOURCE}`
                        : `${apiURL}/public/placeholder_category.jpeg`;

                      return (
                        <div
                          key={`offer-recent-${indexOffer}-${index}`}
                          className="col-md-12">
                          <div className="card pl-5 pr-5 pb-5 pt-5">
                            <NavLink to={item.offer_item_web_url}>
                            <img
                              style={{ height: "200px" }}
                              src={partImg}
                              alt={item.PRODUCT_GROUP}
                            /></NavLink>
                            <div
                              className="pt-5"
                              style={{ minHeight: "130px" }}>
                              <h5 style={{ margin: 0 }}>
                                <a href={item.offer_item_web_url}>
                                  {item.PRODUCT_GROUP}
                                </a>
                              </h5>
                              <p>
                                {t("article_number", {
                                  value: item.ART_ARTICLE_NR,
                                })}
                              </p>
                            </div>
                            {item.VD_PRICE ? (
                              <>
                              <NavLink to={item.offer_item_web_url}>
                                <div className="pb-2" style={{ color: "#999" }}>
                                  <h3 style={{ lineHeight: 0 }}>
                                    {item.display_price}
                                  </h3>
                                  <small> {t("price_included_vat_excluded_delivery")} </small>
                                </div>
                                </NavLink>
                                <button
                                  onClick={() => { handleAddToCart( item.OI_ART_ID, item.ART_ARTICLE_NR, item.PRODUCT_GROUP, 0, 0, 1, partImg, item.offer_item_web_url, ); }}
                                  className="btn btn-dark add-cart mr-2 text-light">
                                  <i className="icon-shopping-cart" />{" "}
                                  <span>{t("add_to_cart")}</span>{" "}
                                </button>
                                
                              </>
                            ) : (
                              <>
                                <div className="pb-2" style={{ color: "#999" }}>
                                  <h3
                                    style={{
                                      lineHeight: 0,
                                      textAlign: "center",
                                    }}>
                                    {t("out_of_stock")}
                                  </h3>
                                  <small style={{ visibility: "hidden" }}>
                                    {t("price_included_vat_excluded_delivery")}
                                  </small>
                                </div>
                                <button
                                  onClick={() => {
                                    window.location = item.offer_item_web_url;
                                    window.scrollTo(0, 0);
                                  }}
                                  className="btn btn-dark add-cart mr-2 text-light">
                                  <i className="icon-shopping-cart" />{" "}
                                  <span>{t("details")}</span>{" "}
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </section>
            )
        )
      )}
    </div>
  );
};

export default Offers;
