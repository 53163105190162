import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

import slugify from "slugify";
import { NavLink, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../../Context/ConfigContext";
import CatelogModal from "./CatelogModal";
import TabsItems from "./TabsItems";
import Tabs from "./Tabs";
const Sections = ({tabNameForeign}) => {
    const { t } = useTranslation();
    const divRef = useRef(null);
   
  

    return(<>
    <section ref={divRef} className="feature-boxes-container" style={{background: '#f7f7f7'}}>
        <center>
            <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                {t('ghayar_auto_parts_buy')}
            </h2>
        </center>
      <div className="container" data-animation-name="fadeInUpShorter" >
      <Tabs />
      
      </div>
    </section>
    </>)
  
}

export default Sections