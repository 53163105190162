import React, { useContext, useEffect, useRef, useState } from "react";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../Context/ConfigContext";

import { useParams } from "react-router-dom";
import { Spinner } from "../../Components/Shimmers";
import slugify from "slugify";
import Carousel from "react-multi-carousel";
import PartsHorizontalCard from "../../Components/Cards/PartsHorizontalCard";
import CarSearchBox from "../Cars/CarSeachBox";
import { Helmet } from "react-helmet";

const Parts = () => {
  const {currency} = useContext(ConfigContext);
  const location = useLocation();
  const { t } = useTranslation();
  const locale = localStorage.getItem("lang") ?? "en";
  const { gdcURL } = useContext(ConfigContext);
  const dir = localStorage.getItem("dir");
  var { slug } = useParams();
  var STR_ID = slug.split("-").slice(-1);
  const [loading, setLoading] = useState(false);
  const [parts, setParts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedOE, setSelectedOE] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedBrandTags, setSelectedBrandTags] = useState([]);
  
  const [ProductGroups, setProductGroups] = useState([]);
  const [TotalOEParts, setTotalOEParts] = useState(0);
  const [TotalIAMParts, setTotalIAMParts] = useState(0);
  const [TotalParts, setTotalParts] = useState(0);
  
  const [CurrentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [TotalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  
  const [responsive, setResponsive] = useState({
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 10,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
  });
  useState(() => {
    if(localStorage.getItem('pc_id') && localStorage.getItem('pc_id') !=0 && localStorage.getItem('pc_id')!=null){

    }else{
      var nocar = window.location.href.replaceAll('Spare-Parts', 'Spare-Parts-nocar');
      window.location = nocar
    }
  }, [])
  const [car_info, setCarInfo] = useState(
    localStorage.getItem("car_info")
      ? JSON.parse(localStorage.getItem("car_info"))
      : []
  );
  const [loadMore, setLoadMore] = useState(false);
  const loadMoreRef = useRef(null); 
  const queryParams = new URLSearchParams(location.search);
  
  // Get the value of the "keyword" parameter
  const selectedPTID = queryParams.get('group');
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("pc_id")) {
        fetch( `${gdcURL}/Parts/GetBrands?pc_id=${localStorage.getItem( "pc_id" )}&str_id=${STR_ID}&lang=${locale}&currency=${currency}&limit=${limit}&page=${page}&pt_id=${selectedPTID}&brands=${selectedBrands}` )
        .then((res) => res.json())
        .then((data) => {
          setBrands(data.data);
        })
        fetch( `${gdcURL}/Parts/GetProductGroups?pc_id=${localStorage.getItem( "pc_id" )}&str_id=${STR_ID}&lang=${locale}&currency=${currency}&limit=${limit}&page=${page}&pt_id=${selectedPTID}&brands=${selectedBrands}` )
        .then((res) => res.json())
        .then((data) => {
          setProductGroups(data.data);
        })
        setSelectedBrands([]);
        setSelectedBrandTags([]);
        setProductGroups([]);
       
        setParts([])
        if(!queryParams.get('supplier')){
          console.log("!query", queryParams.get('supplier'))
          LoadParts();

        }else{
          console.log("query", queryParams.get('supplier'))
        }
        
      }
  }, [location]);
  var supp = queryParams.get('supplier');
  useEffect(() => {
   
    if(supp && supp.split('-')){
      if(queryParams.get('supplier').split('-')){
        console.log("supp",supp.split('-')[0], supp.split('-')[1])
        toggleBrand(parseInt(supp.split('-')[0]), supp.split('-')[1]);
      }
      
    }
  }, [])
  useEffect(() => {
    if(page != 0){
      LoadParts();
    }
    
  }, [page]);
 
  const LoadParts = () => {
   console.log("LoatParts Called")
    if (localStorage.getItem("pc_id")) {
      setLoading(true);
      fetch( `${gdcURL}/Parts/GetPartsByCar?pc_id=${localStorage.getItem( "pc_id" )}&str_id=${STR_ID}&lang=${locale}&currency=${currency}&limit=${limit}&page=${page}&pt_id=${selectedPTID}&brands=${selectedBrands}` )
        .then((res) => res.json())
        .then((data) => {
          setParts((prevProducts) => [...prevProducts, ...data.data]);
          setParts((prevProducts) => {
            var nData = [...prevProducts, ...data.data]
            return removeDuplicates(nData)
          });
         
          setTotalPages(data.paggination.pages);
          setTotalParts(data.paggination.counts);
          setLoading(false);
          setLoadMore(false);
          
        })
        .catch((error) => {
          console.error("Fetch error:", error);
          setLoading(false);
        });
    }
  }
  const removeDuplicates = (array) => {
    const uniqueKeys = new Set();
    return array.filter(item => {
      // Create a unique key for each object based on specified properties
      const key = `${item.ART_ARTICLE_NR}-${item.PRODUCT_GROUP}-${item.SUP_ID}`;
      if (!uniqueKeys.has(key)) {
        uniqueKeys.add(key);
        return true; // Keep the item
      }
      return false; // Discard the item
    });
  };
  useEffect(() => {
    console.log("parts", parts)
  }, [parts])
  useEffect(() => {
    
    setParts([])
    LoadParts();
  }, [selectedBrandTags]);
  
 
  const toggleBrand = (SUP_ID, SUP_BRAND) => {
    setSelectedBrands((prevSelectedBrands) => {
      if (prevSelectedBrands.includes(SUP_ID)) {
        // Remove SUP_ID if it is already in the array
        return prevSelectedBrands.filter((id) => id !== SUP_ID);
      } else {
        // Add SUP_ID if it is not in the array
        return [...prevSelectedBrands, SUP_ID];
      }
    });
    let setSelectedBTs = [...selectedBrandTags]; // Copy the current state

    const index = selectedBrandTags.findIndex((tag) => tag.SUP_ID === SUP_ID);
    if (index === -1) {
      // If the tag is not found, add it
      setSelectedBTs.push({ SUP_ID, SUP_BRAND });
    } else {
      // If the tag is found, remove it
      setSelectedBTs.splice(index, 1);
    }

    setSelectedBrandTags(setSelectedBTs);
    setPage(0);
  };

  const toggleOE = () => {
    if (selectedOE === false || selectedOE === null) {
      setSelectedOE(true);
    } else {
      if (selectedBrandTags.length === 0) {
        setSelectedOE(null);
      } else {
        setSelectedOE(false);
      }
    }
  };

  const handleNext = () => { 
    if(TotalPages && TotalPages > 1 && loadMore === false && page < TotalPages){
      
      setTimeout(() => {
        setLoadMore(true);
        setPage(page + 1);

      }, 1000);
      
      // window.scrollTo(0, 0);
    }
  }

  const handlePrev = () => {
    if(TotalPages && page > 0){
      setPage(page - 1);
      window.scrollTo(0, 0);
    }
  }
  

  const [isSticky, setSticky] = useState(false);
  
  const handleScroll = () => {
    const offset = window.scrollY;
    setSticky(offset > 300); // Change 100 to the scroll value you prefer
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    console.log("isSticky", isSticky);
  }, [isSticky]);
  
  useEffect(() => {
    // Callback function for when the intersection happens
    const handleIntersection = (entries) => {
      const [entry] = entries; // We're only observing one element
      if (entry.isIntersecting) {
        console.log('#TotalPages', TotalPages);
        // setLoadMore(true);
        // handleNext();
        if(parts.length > 0){
          console.log('#1 You found the offer!', TotalPages);
        }else{
          console.log('#2 You found the offer!', TotalPages);
        }
        handleNext();
        
      }
    };

    // Create the IntersectionObserver
    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // Observe within the viewport
      rootMargin: '0px',
      threshold: 0.5, // Trigger when 50% of the element is visible
    });

    // Observe the offer div
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    // Cleanup the observer on unmount
    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [parts]);
  return (
    <main className="main" dir={dir}>
      <Helmet>
          <title>{slug.split("-").slice(0, -1).join(" ")}</title>
          <meta name="keywords" content={localStorage.getItem("pc_id") && loading === false &&
                   
                   parts && parts.length > 0 && ((selectedBrands.length !== 0 && selectedOE === true) ||
                      selectedOE !== true) && 
                    parts.map((part, index) => {
                      return `${part.PRODUCT_GROUP} - ${part.ART_ARTICLE_NR} - ${part.ART_SUP_BRAND}`
                    })
            } />
      </Helmet>
      <div className="row">
        <div className="col-md-3">
          <CarSearchBox redirectStatus={true} />
          {ProductGroups && ProductGroups.length > 0 &&
          <div className="widget widget-size  m-4 pl-4" style={{border: '1px solid #e6e6e6', borderRadius: '5px'}}>
            
            <div className="collapse show" id="widget-body-5">
              
            <div className="widget-body">
            <div className="custom-control custom-radio pb-4" onClick={() =>  {navigate(`/Spare-Parts/${slug}`); window.scrollTo(0, 0)} } style={{borderBottom: '1px solid #e6e6e6', marginTop: 0, marginBottom: 0}}>
              <input type="radio" className="custom-control-input" name="pt_ids" onChange={() => {navigate(`/Spare-Parts/${slug}?group=${selectedPTID}`); window.scrollTo(0, 0)}} checked={selectedPTID === null && true} />
              <label className="custom-control-label">
                {t('all_groups')}
              </label>
              <i className="sicon-arrow-right align-right" style={{float: 'right' , marginRight: '10px',  marginTop: '5px'}}></i>
            </div>
            {ProductGroups &&
              ProductGroups.map((item, index) => {
                return (
                  <div key={`pt-group-${item.PT_ID}-${index}`} className="custom-control custom-radio  pb-4" onClick={() => {window.location.href = `/Spare-Parts/${slug}?group=${item.PT_ID}`; window.scrollTo(0, 0)}} style={{borderBottom: '1px solid #e6e6e6', marginTop: '10px', marginBottom: 0}}>
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="pt_ids"
                      onChange={() => {window.location.href = `/Spare-Parts/${slug}?group=${item.PT_ID}`; window.scrollTo(0, 0)}}
                      checked={selectedPTID && Number(selectedPTID) === item.PT_ID && true}
                    />
                    <label className="custom-control-label">
                      {item.PRODUCT_GROUP} 
                    </label>
                    <i className="sicon-arrow-right align-right" style={{float: 'right' , marginRight: '10px',  marginTop: '5px'}}></i>
                  </div>
                );
              })}
              </div>
            </div>
          </div>
        }
          
        </div>
        <div className="col-md-9" >
          <div className="container">
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to={"/"}>
                    <i className="icon-home" />
                  </NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to={`/Spare-Parts/Catelog`}>{t("catelog")}</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {slug.split("-").slice(0, -1).join(" ")}
                </li>
              </ol>
            </nav>

            {!localStorage.getItem("pc_id") && (
              <div className="container mb-1 mt-1 pb-2 pt-2">
                <div
                  className="alert alert-info" 
                  style={{ textAlign: "center", display: 'none' }}>
                  <div className="col-sm-12">
                    {localStorage.getItem("lang") === null ||
                    localStorage.getItem("lang") === "en" ? (
                      <div>
                        To ensure you get the right parts that fit your car
                        perfectly, please select your car before exploring our
                        wide range of products. This helps us tailor the best
                        options specifically for your make and model,
                        guaranteeing a perfect fit and optimal performance every
                        time.
                        <br />
                        <b>Select Your Car</b> Now and start shopping with
                        confidence!
                      </div>
                    ) : (
                      <div>
                        <b>للحصول على القطع المناسبة لسيارتك!</b>
                        <br />
                        لتضمن حصولك على القطع التي تناسب سيارتك تمامًا، يرجى
                        اختيار سيارتك قبل استكشاف مجموعة منتجاتنا الواسعة.
                        يساعدنا هذا في تقديم أفضل الخيارات التي تتناسب مع طراز
                        سيارتك لضمان ملاءمة مثالية وأداء أمثل في كل مرة.
                        <br />
                        <b>اختر سيارتك الآن وابدأ التسوق بثقة!</b>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {loading === false && brands.length === 0 && localStorage.getItem("pc_id") &&
            <div className="container mb-1 mt-1 pb-2 pt-2">
                <div className="alert alert-info" style={{ textAlign: "center" }}>
                  <div className="col-sm-12">
                    {localStorage.getItem("lang") === null || localStorage.getItem("lang") === "en" ?
                      <div className="no-results">
                          <h2>No Results Found</h2>
                          <p>We couldn't find any parts that match your search. Please try adjusting your search terms or explore other categories.</p>
                          <NavLink to={`/Spare-Parts/Catelog`} className="btn btn-primary">{t('open_catelog')}</NavLink>
                      </div>:

                      <div className="no-results" >
                        <h2>لا توجد نتائج</h2>
                        <p>لم نتمكن من العثور على أي قطع تطابق بحثك. يرجى تعديل كلمات البحث أو استكشاف الفئات الأخرى.</p>
                        <NavLink to={`/Spare-Parts/Catelog`} className="btn btn-primary">{t('open_catelog')}</NavLink>
                      </div>
                      }
                    </div>
                </div>
              </div>
            }


            {parts && parts.length > 0 && 
              localStorage.getItem("pc_id") &&
              car_info.length !== 0 && (
                <h4 className="my_title">
                  {t("actual_offers_of", {
                    category: slug.split("-").slice(0, -1).join(" "),
                    car_info: car_info[0].TYPEL,
                  })}
                </h4>
              )}
            <br />
            <div style={isSticky?{position: 'fixed',top: '-10px',backgroundColor: '#FFF', width: '100%',left: 0,zIndex: '10000000', paddingTop: '20px', paddingLeft: '20px', borderBottom: '1px solid #CCC'}: {}}>
            {parts && parts.length > 0 && 
              localStorage.getItem("pc_id") &&
              selectedBrandTags && (
                <div>
                  <h6 style={{ lineHeight: 0, marginTop: "10px" }}>
                    {t("selected_filters")}
                  </h6>
                  {/* {selectedOE && (
                    <span
                      onClick={() => toggleOE()}
                      className="badge badge-pill  mr-4 pt-2 pb-2"
                      style={{ backgroundColor: "#e9e9e9" }}
                      key={`tags-OE-brand`}>
                      {car_info[0].MFA_BRAND}{" "}
                      <i className="fas fa-times-circle lightColorText"></i>{" "}
                    </span>
                  )} */}
                  {selectedBrandTags &&
                    selectedBrandTags.map((item, index) => {
                      return (
                        <span
                          onClick={() =>
                            toggleBrand(item.SUP_ID, item.SUP_BRAND)
                          }
                          className="badge badge-pill  mr-4 pt-2 pb-2"
                          style={{ backgroundColor: "#e9e9e9" }}
                          key={`tags-${item.SUP_ID}`}>
                          {item.SUP_BRAND}{" "}
                          <i className="fas fa-times-circle lightColorText"></i>{" "}
                        </span>
                      );
                    })}
                </div>
              )}
              
            {parts && localStorage.getItem("pc_id") && (
              <div
                className="container mb-1 mt-1 pb-2 pt-2"
                style={{
                  borderBottom: "1px solid #f3f3f3",
                  borderTop: "1px solid #f3f3f3",
                  
                }}>
                <div className="col-lg-12">
                  {brands && (
                    <Carousel
                      swipeable={true}
                      draggable={true}
                      showDots={false}
                      responsive={responsive}
                      infinite={false}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      // deviceType={this.props.deviceType}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px">
                      {/* {car_info && car_info[0] && car_info[0].PC_MFA_ID && (
                        <div
                          onClick={() => toggleOE()}
                          key={`img-sup-brand-100288`}
                          style={{
                            cursor: "pointer",
                            height: "50px",
                            width: "100px",
                            textAlign: "center",
                            border:
                              selectedOE === false || selectedOE === null
                                ? "1px solid #CCC"
                                : "2px solid #cc000b",
                            marginLeft: "10px",
                          }}>
                          <ImageWithFallback
                            OE={true}
                            src={`${gdcURL}/make_logo/${car_info[0].PC_MFA_ID}.png`}
                            alt={car_info[0].MFA_BRAND}
                            index={101102}
                            fallbackText={car_info[0].MFA_BRAND}
                          />
                        </div>
                      )} */}
                      
                      {brands &&
                        brands.map((img, index) => {
                          return (
                            <div
                              key={`img-sup-brand-${index}`}
                              onClick={() =>
                                {
                                  toggleBrand(img.SUP_ID, img.ART_SUP_BRAND);
                                  window.scrollTo(0, 0)
                                }
                              }
                              style={{
                                cursor: "pointer",
                                height: "50px",
                                width: "100px",
                                textAlign: "center",
                                border:
                                  selectedBrands.includes(img.SUP_ID) === false
                                    ? "1px solid #CCC"
                                    : "2px solid #cc000b",
                                marginLeft: "10px",
                              }}>
                              <ImageWithFallback
                                src={`${gdcURL}/sup-logo/${img.SUP_LOGO_NAME}`}
                                alt={img.ART_SUP_BRAND}
                                index={index}
                                fallbackText={img.ART_SUP_BRAND}
                                OE={false}
                              />
                            </div>
                          );
                        })}
                    </Carousel>
                  )}
                </div>

               
              </div>
            )}
            </div>
            {parts.length > 0 && t('total_items', {total: TotalParts})}
             
            {/* {localStorage.getItem("pc_id") &&
              (selectedBrands.length === 0 || selectedOE === true) && (
                <div
                  className="container mb-1 mt-1 pb-2 pt-2"
                  style={{
                    borderBottom: "1px solid #f3f3f3",
                    borderTop: "1px solid #f3f3f3",
                  }}>
                  <div className="col-lg-12">
                    <OEParts setTotal={setTotalOEParts} STR_ID={STR_ID} SELECTED_PTID={selectedPTID} />
                  </div>
                </div>
              )} */}
            {loading && (
              <div className="mt-5">
                <center>
                  <Spinner />
                </center>
              </div>
            )}
            
            {localStorage.getItem("pc_id") && (
              <div className="row">
                <div className="col-lg-9 order-lg-1"> </div>
                <div className="row pb-4">
                  {parts && parts.length > 0 &&
                    ((selectedBrands.length !== 0 && selectedOE === true) ||
                      selectedOE !== true) && 
                    parts.map((part, index) => {
                      if (
                        selectedBrands.includes(part.SUP_ID) ||  selectedBrands.length === 0 
                      ) {
                        var part_slug =
                          slugify(`${part.PRODUCT_GROUP}`.toLowerCase()) +
                          "-" +
                          slugify(`${part.ART_ARTICLE_NR}`.toLowerCase()) +
                          "-" +
                          STR_ID +
                          "-" +
                          slugify(`${part.SUP_ID}`.toLowerCase()) +
                          "-" +
                          slugify(`${part.ART_ID}`.toLowerCase()) +
                          "#" +
                          part.VD_PRICE;
                        return (
                          <PartsHorizontalCard
                            key={`r-part-id-${part.ART_ID}-${index}`}
                            partImg={part.MEDIA_SOURCE}
                            criteria={part.ARTICLE_CRITERIA}
                            url={`/Spare-Parts/Details/${part_slug}`}
                            id={part.ART_ID}
                            title={part.PRODUCT_GROUP}
                            number={part.ART_ARTICLE_NR}
                            price={part.VD_PRICE ?? 0}
                            display_price={part.display_price ?? 0}
                            mrp={part.VD_PRICE_MRP ?? 0}
                            display_price_mrp={part.display_price_mrp ?? 0}
                            qty={1}
                            imgUrl={""}
                            action={true}
                            brand={part.ART_SUP_BRAND}
                            suplogo={part.SUP_LOGO_NAME}
                            supid={part.SUP_ID}
                            redLabel={3 === 3 ? `Aftermarket` : "Genuine"}
                            partInfo={part.ART_INFO}
                            pt_intl={part.PT_INTL}
                          />
                        );
                      }
                    })}
                </div>
              </div>
            )}
            <div ref={loadMoreRef} style={{visibility: 'hidden'}}>LoadMOre {TotalPages} === {page}</div>
            <div className="row justify-content-center">
              <div className="col-sm-2 pb-5">
              {loadMore === true && (
              <div className="mt-5">
                <center>
                  <Spinner />
                </center>
              </div>
            )}
                {/* <ul className="pagination toolbox-item" style={{margin: 'auto', float: 'none'}}>
                <li className="page-item" onClick={() => {handlePrev()}}>
                  <a className="page-link page-link-btn">
                    <i className="icon-angle-left" />
                  </a>
                </li>
                 {
                  new Array(TotalPages).fill().map((_,index) => {
                    return <li className={`page-item ${index === page && 'active'}`} onClick={() => {setPage(index);  window.scrollTo(0, 0);}}>
                    <a className="page-link">
                      {index+1} {index === page && <span className="sr-only">(current)</span>}
                    </a>
                  </li>
                  })
                 }
                  
                  
                  <li className="page-item" onClick={() => {handleNext()}}>
                    <a className="page-link page-link-btn">
                      <i className="icon-angle-right" />
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>

          </div>
        </div>
      </div>
    </main>
  );
};
const ImageWithFallback = ({ src, index, alt, fallbackText, OE }) => {
  const [imgError, setImgError] = useState(false);

  const handleError = () => {
    setImgError(true);
  };
  const fallbackStyle = {
    textAlign: "center",
    fontSize: "10px",
    padding: "10px",
    wordBreak: "break-word",
    maxWidth: "90%",
  };

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return (
    <div style={containerStyle}>
      {imgError ? (
        <div style={fallbackStyle}>{fallbackText}</div>
      ) : (
        <img
          draggable="false"
          style={{
            height: "40px",
            margin: "auto",
            float: "none",
            marginTop: OE === false ? "5px" : 0,
          }}
          key={`img-part-${index}`}
          src={src}
          alt={alt}
          onError={handleError}
        />
      )}
    </div>
  );
};
export default Parts;
